import './entry'
import Vue from 'vue'
import 'normalize.css/normalize.css'
import '@/assets/style/main.scss'
import '@/common/polyfills'
import App from './App.vue'
import router from './router'
import store from './store/store'
import './plugins/element.js'
import VueLogger from 'vuejs-logger'
import VueVirtualScroller from 'vue-virtual-scroller'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import i18n from "./plugins/i18n"

import "./plugins/data-dog-config.js";

const isProduction = hkConfigEnvs.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}

Vue.use(VueLogger, options)
Vue.use(VueVirtualScroller)

Vue.config.productionTip = false


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
