
import { datadogRum } from '@datadog/browser-rum';
if(window.hkConfigEnvs.VUE_DATA_DOG_ENABLE){
    datadogRum.init({
        applicationId: window.hkConfigEnvs.VUE_DATA_DOG_APP_ID,
        clientToken: window.hkConfigEnvs.VUE_DATA_DOG_CLIENT_TOKEN,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: window.hkConfigEnvs.VUE_DATA_DOG_SITE,
        service: window.hkConfigEnvs.VUE_DATA_DOG_SERVICE,
        env: window.hkConfigEnvs.VUE_DATA_DOG_ENV,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
    });
    // Start collecting RUM events
    datadogRum.startSessionReplayRecording();
}